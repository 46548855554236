<template>
  <aside class="left_menu">
    <div class="logo">
      <router-link to="/dashboard">
        <img class="logonew" src="@/assets/images/logo2.png" />
      </router-link>
    </div>

    <ul>
      <li>
        <router-link
          to="/dashboard"
          :class="{ active: current_route == 'dashboard' }"
          ><i class="dashboard"></i>Dashboard</router-link
        >
      </li>
      <li v-if="checkPermessions('clients')">
        <router-link
          to="/client-list"
          :class="{ active: current_route == 'client-list' }"
          ><i class="clients"></i>Clients</router-link
        >
      </li>

      <li v-if="checkProperty(this.userdata, 'roleId') == 5">
        <router-link
          :to="
            '/client-details/' + checkProperty(userdata, 'clientDetails', '_id')
          "
          :class="{ active: current_route == 'client-details' }"
          ><i class="clients"></i>Business Profile</router-link
        >
      </li>

      <li v-if="checkPermessions('fillings')">
        <router-link
          to="/fillings"
          :class="{
            active:
              current_route == 'fillings' || current_route == 'filing-details',
          }"
          ><i class="fillings"></i>Filings
        </router-link>
      </li>
      <li v-if="checkPermessions('transactions')">
        <router-link
          to="/transactions"
          :class="{ active: current_route == 'transactions' }"
          ><i class="transactions"></i>Transactions</router-link
        >
      </li>
      <li v-if="checkPermessions('deadlines')">
        <router-link
          to="/deadlines"
          :class="{
            active:
              current_route == 'deadlines' || current_route == 'Filing Details',
          }"
          ><i class="deadlines"></i>Deadlines</router-link
        >
      </li>
      <!-- <li v-if="checkPermessions('settings')" ><router-link to="/settings" :class="{'active':current_route=='Settings'}" ><i class="settings"></i>Settings</router-link></li>-->
      <li v-if="checkPermessions('notes')">
        <router-link to="/notes" :class="{ active: current_route == 'notes' }"
          ><i class="notes"></i>Notes</router-link
        >
      </li>
      <li v-if="checkPermessions('users')">
        <router-link to="/users" :class="{ active: current_route == 'users' }"
          ><i class="users"></i>Users</router-link
        >
      </li>
    </ul>
  </aside>
</template>

<script>
export default {
  name: "sidebar",
  computed: {
    current_route() {
      return this.$route.name.trim();
    },
  },

  data() {
    return {};
  },
  mounted() {},
  methods: {
    checkPermessions(menu = "") {
      let permessions = [
        "dashboard",
        "clients",
        "fillings",
        "transactions",
        "deadlines",
        "settings",
        "notes",
        "users",
      ];
      if (this.userdata.roleId == 1) {
        return true;
      }
      if (this.userdata.roleId == 2) {
        //support team
        permessions = [
          "dashboard",
          "clients",
          "fillings",
          "transactions",
          "deadlines",
          "settings",
          "notes",
        ];
        if (permessions.indexOf(menu) > -1) {
          return true;
        } else {
          return false;
        }
      }
      if (this.userdata.roleId == 3) {
        permessions = [
          "dashboard",
          "clients",
          "fillings",
          "transactions",
          "deadlines",
          "settings",
          "notes",
          "users",
        ];
        if (permessions.indexOf(menu) > -1) {
          return true;
        } else {
          return false;
        }
      }
      if (this.userdata.roleId == 4) {
        permessions = [
          "dashboard",
          "fillings",
          "transactions",
          "deadlines",
          "settings",
          "notes",
          "users",
        ];
        if (permessions.indexOf(menu) > -1) {
          return true;
        } else {
          return false;
        }
      }

      if (this.userdata.roleId == 5) {
        permessions = [
          "dashboard",
          //"fillings",
          //"transactions",
          "notes",
          "businessProfile",
        ];
        if (permessions.indexOf(menu) > -1) {
          return true;
        } else {
          return false;
        }
      }
    },
  },
  beforeCreate() {
    this.userdata = this.$store.getters["auth/getuser"];
    // this.userdata = localStorage.getItem('user');
  },
};
</script>
