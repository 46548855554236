<template>
  <!-- <body> -->

  <div>
    <!-- home-header started -->

    <div class="wrapper">
      <sideBar />
      <router-view />
      <!-- <div class="body_section">

                 <profileHeader :showFilter="currentRoute"  :showGoback="true"  @changedGlobalclient="changedGlobalclient"   />

                  <div class="body_content">

                    <router-view  />


                    <footerView ref="rout_view" ></footerView>

                </div>
            </div> -->
      <!-- <div v-if="showLoading" class="page_loader"><img src="@/assets/images/loader.gif"></div>-->
    </div>

    <!-- <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>
        <script src="https://kit.fontawesome.com/50d85a59fb.js" crossorigin="anonymous"></script>

        <script type="text/javascript" src="@/assets/js/bootstrap.min.js"></script>
        <script type="text/javascript" src="@/assets/js/bootstrap-select.min.js"></script>
        <script type="text/javascript" src="@/assets/js/custom.js"></script>
        <script type="text/javascript">
            // $(document).ready(function(){
            //     $('#myselect').selectpicker();
            // });
        </script> -->

    <!-- </body> -->
  </div>
</template>

<script>
//import profileHeader from "@/components/ProfileHeader.vue"
import sideBar from "@/components/sideBar.vue";
//import footerView from "@/components/footer.vue"
export default {
  name: "layout",
  methods: {
    changedGlobalclient(cl) {
      this.log(cl);
      //this.$refs.rout_view.serchTitle(cl);
    },
  },
  watch: {},
  components: {
    //profileHeader,
    sideBar,
    //footerView
  },
  data() {
    return {
      userdata: null,
      showClientFilter: true,
    };
  },
  mounted() {
    this.userdata = this.$store.getters["auth/getuser"];

    if (this.$route.name == "users") {
      this.showClientFilter = false;
    }
  },

  computed: {},
  beforeCreate() {
    document.title = "Your TaxHero";
    //console.log(this.showLoading);
  },
  beforeUpdate() {
    // alert('beforeUpdate');
    // console.log(this.showLoading);
  },
  Updated() {
    //console.log(this.showLoading);
    //   alert('Updated');
  },
};
</script>
